import React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import { faComments } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ContactForm from "../components/contact-us-form"
import { Container, Row, Col } from "react-bootstrap"

export default () => (
  <Layout style={{ color: `teal` }}>
    <Helmet title="Contact | RWK Attorneys" defer={false} />

    <Container className="my-5">
      <Row className="justify-content-center mb-4">
        <Col md={{ span: 5 }}>
          <div className="text-center">
            <FontAwesomeIcon
              icon={faComments}
              className="display-1 text-primary"
            />
            <h1>Contact</h1>
            <p className="lead text-muted">
              We take great interest in taking care of you.
            </p>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={{ span: 4 }} className="bg-light p-5">
          <h1>Contact info</h1>
          <strong>Birmingham Office</strong>
          <address>
            315 Gadsden Hwy, Suite D<br />
            Birmingham, AL 35235
            <br />
          </address>

          <strong>Pell City Office</strong>
          <address>
            1917 Cogswell Avenue,
            <br />
            Pell City, AL 35125
          </address>

          <strong>Mail</strong>
          <address>support@rwkattorneys.com</address>
          <strong>Call</strong>
          <address>
            (205) 833-2589
            <br />
          </address>

          <section className="d-sm-none mt-5">
            <h5>Business Hours</h5>
            <dl class="row">
              <dt class="col-sm-3">Mon:</dt>
              <dd class="col-sm-9">8:30am - 5:00pm</dd>

              <dt class="col-sm-3">Tue:</dt>
              <dd class="col-sm-9">8:30am - 5:00pm</dd>

              <dt class="col-sm-3">Wed:</dt>
              <dd class="col-sm-9">8:30am - 5:00pm</dd>

              <dt class="col-sm-3">Thu:</dt>
              <dd class="col-sm-9">8:30am - 5:00pm</dd>

              <dt class="col-sm-3">Fri:</dt>
              <dd class="col-sm-9">8:30am - 5:00pm</dd>

              <dt class="col-sm-3">Sat:</dt>
              <dd class="col-sm-9">Closed</dd>

              <dt class="col-sm-3">Sun:</dt>
              <dd class="col-sm-9">Closed</dd>
            </dl>
          </section>
        </Col>
        <Col md={{ offset: 1 }} className="mt-4">
          <ContactForm />
        </Col>
      </Row>
    </Container>
  </Layout>
)
